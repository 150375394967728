export enum LocationType {
  STORE = 'store',
  SALES_CUPBOARD = 'salesCupboard',
  POINT_OF_SALE = 'pointOfSale',
  FARM = 'farm',
  MARKET = 'market',
  GROCERY_STORE = 'groceryStore',
  EVENT = 'event',
}

export interface OpeningHours {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
}

export interface FormValues {
  name: string;
  slug?: string;
  address?: string;
  city: string;
  postalCode?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  googleMapsLink?: string;
  locationType: LocationType;
  openingHours?: OpeningHours;
  exceptionalClosures?: string;
  consultableDates?: string;
  openingDates?: string;
  contactPhone?: string;
  contactEmail?: string;
  website?: string;
  socialMediaLinks?: string;
  tags?: string;
  public: boolean;
  description?: string;
}

export interface ExceptionalClosure {
  date: string;
  reason: string;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export type SocialMediaLinks = {
  facebook?: string;
  instagram?: string;
  linkedin?: string;
  twitter?: string;
};

export interface IDates {
  startDate?: string;
  endDate?: string;
}

export interface ILocation {
  id?: string;
  name: string;
  slug?: string;
  address?: string;
  city: string;
  postalCode?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  googleMapsLink?: string;
  locationType: LocationType;
  openingHours?: OpeningHours;
  exceptionalClosures?: ExceptionalClosure[];
  consultableDates?: IDates[];
  openingDates?: IDates[];
  contactPhone?: string;
  contactEmail?: string;
  website?: string;
  socialMediaLinks?: SocialMediaLinks;
  tags?: string[];
  public: boolean;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FetchLocationParams {
  page: number;
  pageSize: number;
  searchText?: string;
}
