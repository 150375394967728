import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { Contributor } from '../../interfaces/contributor';

interface AllContributorsResponse {
  contributors: Contributor[];
}

const fetchAllContributorCategories = async () => {
  try {
    const { data } = await axiosInstance.get('/contributors/all');
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributors:', error);
    throw new Error('Failed to fetch contributors');
  }
};

export const useAllContributor = () => {
  return useQuery<AllContributorsResponse>({
    queryKey: ['allContributor'],
    queryFn: fetchAllContributorCategories,
  });
};
