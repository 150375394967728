import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { ContributorCategory } from '../../interfaces/contributor';

interface ContributorCategoriesResponse {
  contributorCategories: ContributorCategory[];
}

const fetchContributorCategories = async () => {
  try {
    const { data } = await axiosInstance.get(
      '/contributorCategory/getAllCategory'
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error fetching contributor categories:', error);
    throw new Error('Failed to fetch contributor categories');
  }
};

export const useContributorCategories = () => {
  return useQuery<ContributorCategoriesResponse>({
    queryKey: ['contributorCategories'],
    queryFn: fetchContributorCategories,
  });
};
