import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { Contributor } from '../../interfaces/contributor';

interface UpdateContributorData extends Partial<Contributor> {
  categoryCompanyIds: string[];
  picture?: File;
}

const editContributor = async (
  contributorId: string,
  updatedData: UpdateContributorData
) => {
  try {
    const { data } = await axiosInstance.patch(
      `/contributor/${contributorId}`,
      updatedData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing contributor:', error);
    throw new Error('Failed to edit contributor');
  }
};

export const useEditContributor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      contributorId,
      updatedData,
    }: {
      contributorId: string;
      updatedData: UpdateContributorData;
    }) => editContributor(contributorId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['contributors'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
