import { FC } from 'react';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Textarea,
  Checkbox,
  FileInput,
  Select,
} from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useContributorCategories } from '../../hooks/api/useContributorCategory';
import { useAddContributor } from '../../hooks/api/useAddContributor';

export interface AddContributorProps {
  open: boolean;
  onClose: () => void;
}

export interface FormValues {
  tradeName: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  address: string;
  postalCode: string;
  city: string;
  latitude: number;
  longitude: number;
  companyName: string | null;
  sirenNumber: string | null;
  website: string | null;
  description: string;
  openingHours: string | null;
  picture: FileList | null;
  category: string | null;
  hasPointOfSale: boolean;
}

const AddContributorModal: FC<AddContributorProps> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();

  const selectedCategory = watch('category');

  const { mutate: addContributor } = useAddContributor();
  const { data: categories } = useContributorCategories();

  const onSubmit = (data: FormValues) => {
    const { latitude, longitude, category, ...restOfData } = data;
    const picture = data.picture?.[0];

    const processedData = {
      ...restOfData,
      coordinates: { latitude, longitude },
      categoryCompanyIds: category ? [category] : [],
      picture: picture,
      firstName: restOfData.firstName || null,
      lastName: restOfData.lastName || null,
      email: restOfData.email || null,
      phoneNumber: restOfData.phoneNumber || null,
      companyName: restOfData.companyName || null,
      sirenNumber: restOfData.sirenNumber || null,
      website: restOfData.website || null,
      openingHours: restOfData.openingHours || null,
      description: restOfData.description,
      hasPointOfSale: restOfData.hasPointOfSale,
    };

    addContributor(
      {
        contributorToCreate: processedData,
      },
      {
        onSuccess: onClose,
      }
    );
  };

  return (
    <Modal onClose={onClose} size="4xl" show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('contributorAddModal.title')}</strong>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
            <div>
              <Label htmlFor="tradeName">
                {t('contributorAddModal.tradeName')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="tradeName"
                  type="text"
                  {...register('tradeName', {
                    required: 'Trade name is required',
                  })}
                />
                {errors.tradeName && (
                  <p className="text-red-500">{errors.tradeName.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="address">
                {t('contributorAddModal.address')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="address"
                  type="text"
                  {...register('address', { required: 'Address is required' })}
                />
                {errors.address && (
                  <p className="text-red-500">{errors.address.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="postalCode">
                {t('contributorAddModal.postalCode')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="postalCode"
                  type="text"
                  {...register('postalCode', {
                    required: 'Postal code is required',
                  })}
                />
                {errors.postalCode && (
                  <p className="text-red-500">{errors.postalCode.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="city">{t('contributorAddModal.city')}</Label>
              <div className="mt-1">
                <TextInput
                  id="city"
                  type="text"
                  {...register('city', { required: 'City is required' })}
                />
                {errors.city && (
                  <p className="text-red-500">{errors.city.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="firstName">
                {t('contributorAddModal.firstName')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="firstName"
                  type="text"
                  {...register('firstName')}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="lastName">
                {t('contributorAddModal.lastName')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="lastName"
                  type="text"
                  {...register('lastName')}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="email">{t('contributorAddModal.email')}</Label>
              <div className="mt-1">
                <TextInput id="email" type="email" {...register('email')} />
              </div>
            </div>

            <div>
              <Label htmlFor="phoneNumber">
                {t('contributorAddModal.phoneNumber')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="phoneNumber"
                  type="tel"
                  {...register('phoneNumber')}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="latitude">
                {t('contributorAddModal.latitude')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="latitude"
                  type="number"
                  step="any"
                  {...register('latitude', {
                    required: 'latitude is required',
                  })}
                />
                {errors.latitude && (
                  <p className="text-red-500">{errors.latitude.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="longitude">
                {t('contributorAddModal.longitude')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="longitude"
                  type="number"
                  step="any"
                  {...register('longitude', {
                    required: 'longitude is required',
                  })}
                />
                {errors.longitude && (
                  <p className="text-red-500">{errors.longitude.message}</p>
                )}
              </div>
            </div>

            <div>
              <Label htmlFor="companyName">
                {t('contributorAddModal.companyName')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="companyName"
                  type="text"
                  {...register('companyName')}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="sirenNumber">
                {t('contributorAddModal.sirenNumber')}
              </Label>
              <div className="mt-1">
                <TextInput
                  id="sirenNumber"
                  type="text"
                  {...register('sirenNumber')}
                />
              </div>
            </div>

            <div>
              <Label htmlFor="website">
                {t('contributorAddModal.website')}
              </Label>
              <div className="mt-1">
                <TextInput id="website" type="text" {...register('website')} />
              </div>
            </div>

            <div>
              <Label htmlFor="category">
                {t('contributorAddModal.category')}
              </Label>
              <div className="mt-1">
                <Select id="category" {...register('category')}>
                  {categories?.contributorCategories.map((cat) => (
                    <option
                      key={cat.slug}
                      value={cat.categoryCompanyId}
                      selected={selectedCategory === cat.categoryCompanyId}
                    >
                      {cat.label}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
            <div>
              <Label htmlFor="pictureUrl">
                {t('contributorAddModal.picture')}
              </Label>
              <div className="mt-1">
                <FileInput
                  id="pictureUrl"
                  accept="image/png, image/jpeg"
                  {...register('picture')}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="description">
                {t('contributorAddModal.description')}
              </Label>
              <div className="mt-1">
                <Textarea
                  id="description"
                  {...register('description', {
                    required: 'description is required',
                  })}
                />
                {errors.description && (
                  <p className="text-red-500">{errors.description.message}</p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="hasPointOfSale">
                {t('contributorAddModal.hasPointOfSale')}
              </Label>
              <div className="mt-1">
                <Checkbox id="hasPointOfSale" {...register('hasPointOfSale')} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">{t('contributorAddModal.saveButton')}</Button>
          <Button color="gray" onClick={onClose}>
            {t('contributorAddModal.cancel')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddContributorModal;
