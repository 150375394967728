import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { Contributor } from '../../interfaces/contributor';
import { v4 as uuidv4 } from 'uuid';

interface AddContributorData extends Partial<Contributor> {
  categoryCompanyIds: string[];
  picture?: File;
}

const addContributor = async (contributorToCreate: AddContributorData) => {
  try {
    const contributorId = uuidv4();

    const { data } = await axiosInstance.put(
      `/contributor/proposal/${contributorId}`,
      contributorToCreate
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error adding contributor:', error);
    throw new Error('Failed to add contributor');
  }
};

export const useAddContributor = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      contributorToCreate,
    }: {
      contributorToCreate: AddContributorData;
    }) => addContributor(contributorToCreate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['contributors'],
      });
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
