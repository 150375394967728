import { useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import * as Sentry from '@sentry/react';
import { Product } from '../../interfaces/product';

interface UpdateProductData extends Partial<Product> {
  contributorId?: string | null;
  labels?: string[] | undefined;
  picture?: File;
}

const editProduct = async (
  productId: string,
  updatedData: UpdateProductData
) => {
  try {
    const { data } = await axiosInstance.patch(
      `/product/${productId}`,
      updatedData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error) {
    Sentry.captureException(error);
    console.error('Error editing product:', error);
    throw new Error('Failed to edit product');
  }
};

export const useEditProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      productId,
      updatedData,
    }: {
      productId: string;
      updatedData: UpdateProductData;
    }) => editProduct(productId, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries(['products'] as any);
    },
    onError: (error) => {
      Sentry.captureException(error);
      console.error('Error during mutation:', error);
    },
  });
};
