import { FC, useMemo } from 'react';
import {
  Button,
  Label,
  Modal,
  TextInput,
  Textarea,
  Checkbox,
  FileInput,
  Select,
} from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Contributor } from '../../interfaces/contributor';
import { useEditContributor } from '../../hooks/api/updateContributor';
import { useContributorCategories } from '../../hooks/api/useContributorCategory';

export interface EditContributorProps {
  open: boolean;
  onClose: () => void;
  contributor: Contributor;
}

export interface Coordinates {
  latitude: number;
  longitude: number;
}

export interface FormValues {
  tradeName: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  address: string;
  postalCode: string;
  city: string;
  latitude: number;
  longitude: number;
  companyName: string | null;
  sirenNumber: string | null;
  website: string | null;
  description: string;
  openingHours: string | null;
  picture: FileList | null;
  category: string | null;
  hasPointOfSale: boolean;
}

const EditContributorModal: FC<EditContributorProps> = ({
  open,
  onClose,
  contributor,
}) => {
  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => ({
      tradeName: contributor.tradeName,
      firstName: contributor.firstName,
      lastName: contributor.lastName,
      email: contributor.email,
      phoneNumber: contributor.phoneNumber,
      address: contributor.address,
      postalCode: contributor.postalCode,
      city: contributor.city,
      latitude: contributor.coordinates.latitude,
      longitude: contributor.coordinates.longitude,
      companyName: contributor.companyName,
      sirenNumber: contributor.sirenNumber,
      website: contributor.website,
      description: contributor.description,
      openingHours: contributor.openingHours,
      hasPointOfSale: contributor.hasPointOfSale,
      pictureUrl: contributor.pictureUrl,
      category: contributor.categories[0]?.categoryCompanyId,
    }),
    [contributor]
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
  });

  const selectedCateogory = watch('category');

  const { mutate: editContributor } = useEditContributor();
  const { data: categories } = useContributorCategories();

  const onSubmit = (data: FormValues) => {
    const { latitude, longitude, category, ...restOfData } = data;
    const picture = data.picture?.[0];

    editContributor(
      {
        contributorId: contributor.id,
        updatedData: {
          ...restOfData,
          coordinates: { latitude, longitude },
          // for now we only support 1 category per contributor
          categoryCompanyIds: category ? [category] : [],
          picture: picture,
        },
      },
      {
        onSuccess: onClose,
      }
    );
  };

  return (
    <Modal onClose={onClose} size="4xl" show={open}>
      <Modal.Header className="border-b border-gray-200 !p-6 dark:border-gray-700">
        <strong>{t('contributorEditModal.title')}</strong>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
            {/* Form Fields */}
            {[
              {
                label: t('contributorEditModal.tradeName'),
                id: 'tradeName',
                type: 'text',
                validation: 'Trade name is required',
              },
              {
                label: t('contributorEditModal.firstName'),
                id: 'firstName',
                type: 'text',
                validation: 'First name is required',
              },
              {
                label: t('contributorEditModal.lastName'),
                id: 'lastName',
                type: 'text',
                validation: 'Last name is required',
              },
              {
                label: t('contributorEditModal.email'),
                id: 'email',
                type: 'email',
                validation: 'Email is required',
              },
              {
                label: t('contributorEditModal.phoneNumber'),
                id: 'phoneNumber',
                type: 'tel',
                validation: 'Phone number is required',
              },
              {
                label: t('contributorEditModal.address'),
                id: 'address',
                type: 'text',
                validation: 'Address is required',
              },
              {
                label: t('contributorEditModal.postalCode'),
                id: 'postalCode',
                type: 'text',
                validation: 'Postal code is required',
              },
              {
                label: t('contributorEditModal.city'),
                id: 'city',
                type: 'text',
                validation: 'City is required',
              },
              {
                label: t('contributorEditModal.latitude'),
                id: 'latitude',
                type: 'number',
                step: 'any',
                validation: 'Latitude is required',
              },
              {
                label: t('contributorEditModal.longitude'),
                id: 'longitude',
                type: 'number',
                step: 'any',
                validation: 'Longitude is required',
              },
            ].map((field) => (
              <div key={field.id}>
                <Label htmlFor={field.id}>{field.label}</Label>
                <div className="mt-1">
                  <TextInput
                    id={field.id}
                    type={field.type}
                    step={field.step}
                    {...register(field.id as any, {
                      required: field.validation,
                    })}
                  />
                  {errors[field.id as keyof FormValues] && (
                    <p className="text-red-500">
                      {errors[field.id as keyof FormValues]?.message}
                    </p>
                  )}
                </div>
              </div>
            ))}

            {/* Other Fields (category, file upload, checkbox, etc.) */}
            <div>
              <Label htmlFor="category">
                {t('contributorEditModal.category')}
              </Label>
              <div className="mt-1">
                <Select id="category" {...register('category')}>
                  {categories?.contributorCategories.map((cat) => (
                    <option
                      key={cat.slug}
                      value={cat.categoryCompanyId}
                      selected={selectedCateogory === cat.categoryCompanyId}
                    >
                      {cat.label}
                    </option>
                  ))}
                </Select>
                {errors.category && (
                  <p className="text-red-500">{errors.category?.message}</p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="pictureUrl">
                {t('contributorEditModal.picture')}
              </Label>
              <div className="mt-1">
                <FileInput
                  id="pictureUrl"
                  accept="image/png, image/jpeg"
                  {...register('picture')}
                />
                {errors.picture && (
                  <p className="text-red-500">{errors.picture?.message}</p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="description">
                {t('contributorEditModal.description')}
              </Label>
              <div className="mt-1">
                <Textarea id="description" {...register('description')} />
                {errors.description && (
                  <p className="text-red-500">{errors.description?.message}</p>
                )}
              </div>
            </div>
            <div>
              <Label htmlFor="hasPointOfSale">
                {t('contributorEditModal.hasPointOfSale')}
              </Label>
              <div className="mt-1">
                <Checkbox id="hasPointOfSale" {...register('hasPointOfSale')} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">{t('contributorEditModal.saveButton')}</Button>
          <Button color="gray" onClick={onClose}>
            {t('contributorEditModal.cancel')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditContributorModal;
